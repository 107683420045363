#pacifica-streaming {
    & .seccion-unoo {
        background-image: url('../../../Resources/images/fcaMobi/web_bg1.png');
        background-position: center top;
        background-size: cover;
        padding: 32px 0 32px 0;
        overflow: hidden;
    }

    & .seccion-dos {
        background-image: url('../../../Resources/images/fcaMobi/web_bg2.png');
        background-position: center top;
        background-size: cover;
        padding: 25px 0 0px 0;
        margin-bottom: -10px;
        overflow: hidden;

        & .logo-spira {
            width: 150px;
            margin-top: 30px;
        }
    }

    & .mobi-footer {
        background: #000;
        padding: 50px 0;
        margin-top: 30px;
    }

}