.bg-header-mobi {
	background-color: #000;
}

#mobi {
	height: 100vh;
	display: table;
	width: 100vw;

	&::after {
		background-image: url(../../../Resources/images/fcaMobi/login_bg.png);
		content: "";
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1;
		background-repeat: no-repeat;
		background-position-y: 100%;
		background-position-x: center;
		background-size: cover;
		background-position: center;
	}

	& .contenedor-form-mobi {
		display: table-cell;
		vertical-align: middle;

		& .form-ingreso {
			text-align: center;
			background-color: #fff;
			background-image: url(../../../Resources/images/fcaMobi/loginform_bghor.svg);
			background-repeat: no-repeat;
			background-size: cover;

			&.bg-mobi {
				background-color: #fff;
				padding: 10px;
			}

			& .MuiInputLabel-outlined.MuiInputLabel-shrink {
				transform: translate(14px, -17px) scale(1) !important;
				font-family: 'Lato' !important;
				font-weight: normal;
				font-size: 16px;
			}

			& .MuiInputLabel-outlined.MuiInputLabel-shrink {
				transform: translate(0px, -25px) scale(1) !important;
				font-family: 'Lato' !important;
				font-size: 20px;
				color: #000;
			}

			& .MuiOutlinedInput-input {
				background: transparent linear-gradient(90deg, #fff 0%, #fff 24%, #fff 68%, #fff 100%) 0% 0% no-repeat padding-box !important;
				border: 1px solid !important;
			}

			& .MuiInputLabel-outlined {
				
				font-size: 16px;
				font-family: 'Lato' !important;
			}

			& .MuiOutlinedInput-notchedOutline {
				border: #3d5295 !important;
			}

			& .MuiFormLabel-root.Mui-focused {
				
				font-weight: normal;
				font-size: 14px;
			}

			& .MuiButton-root:hover {
				text-decoration: none !important;
				background-color: transparent !important;
			}

			& .texto-btn-login {
				font-size: 23px;
				font-weight: bolder;
				z-index: 9;
				color: #fff;
				border: none;
				font-family: 'Lato' !important;
				padding: 5px;
				background: #000;
				width: 200px;
			}
		}
	}
}

@media screen and (max-width: 425px) and (orientation: portrait) {
	#mobi {
		margin-bottom: 30px;
		& .contenedor-form-mobi {
			& .form-ingreso {
				margin-top: 20px;
			}
		}
	}

}

@media screen and (max-width: 1100px) and (orientation: landscape) {
	#mobi {
		margin-bottom: 30px;

		& .contenedor-form-mobi {
			& .form-ingreso {
				margin-top: 20px;
			}
		}
	}
}