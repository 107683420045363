@font-face {
  font-family: "DIN Condensed Bold";
  src: url("../../../Resources/fonts/DIN-Condensed-Bold.ttf");
}

.bg-header-bridgestoneVentas {
  background-image: url(../../../Resources/images/bridgestoneVentas/bg_header.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#bridgestoneVentas {
  font-family: "DIN Condensed Bold";

  &::after {
    background-image: url(../../../Resources/images/bridgestoneVentas/bg_Login.png);
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: cover;
    background-position: center;
    font-family: "DIN Condensed Bold";
  }

  & .form-ingreso {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-wrap: wrap;

    & .titulo {
      color: #fff;
      font-size: 50px;
      margin-bottom: -10px;
    }
    & .titulo2 {
      color: #fff;
      font-size: 40px;
      margin-bottom: -50px;
    }
    & .texto1 {
      color: #fff;
      font-size: 30px;
      margin-bottom: 10px;
    }
    & .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 130px;
      margin-top: 80px;
      //   margin-bottom: 30px;
    }
    & .loginForm {
      width: 82%;
    }
    & .img-titulo {
      width: auto;
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(0px, -34px) scale(1) !important;
      font-family: "Lato" !important;
      font-weight: bold;
      color: #fff !important;
      font-size: 20px;
    }

    & .MuiOutlinedInput-input {
      background: transparent linear-gradient(90deg, #fff 0%, #fff 24%, #fff 68%, #fff 100%) 0% 0% no-repeat padding-box !important;
      color: #54565b !important;
      border: none !important;
    }

    & .MuiInputLabel-outlined {
      color: #54565b !important;
      font-size: 16px;
      font-family: "Lato" !important;
      font-weight: bold;
    }

    & .MuiInputBase-root {
      // margin-bottom: 30px;
    }

    & .MuiOutlinedInput-notchedOutline {
      border: #54565b !important;
    }

    & .MuiFormLabel-root.Mui-focused {
      color: #fff !important;
      font-weight: normal;
      font-size: 20px;
      font-weight: bold;
    }

    & .MuiButton-root:hover {
      text-decoration: none !important;
      background-color: transparent !important;
    }

    & .btn-login {
      margin-bottom: 70px;
    }

    & .texto-btn-login {
      font-size: 23px;
      font-weight: bolder;
      z-index: 9;
      color: #fff;
      border: none;
      font-family: "Lato" !important;
      padding: 5px;
      background: #e2211b;
      width: 200px;
      margin-bottom: 40px;
    }

    & .fondoForm {
      background-image: url(../../../Resources/images/bridgestoneVentas/bg_login_cont.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
}

@media (max-width: 500px) {
  .img-titulo {
    width: 90vw !important;
  }
  .fondoForm {
    background-image: url() !important;
  }
}
