@font-face {
	font-family: 'Poppins Bold';
	src: url('../../../Resources/fonts/Poppins-Bold.ttf');
}

@font-face {
	font-family: 'Poppins Regular';
	src: url('../../../Resources/fonts/Poppins-Regular.ttf');
}

#bettertogether {
	font-family: 'Poppins Regular' !important;
	font-weight: normal !important;

	&::after {
		background-image: url(../../../Resources/images/bettertogether/Fondo.png);
		content: "";
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1;
		background-repeat: no-repeat;
		background-position-y: 100%;
		background-position-x: center;
		background-size: cover;
		background-position: center;
	}

	& .form-ingreso {
		text-align: center;

		& .logoss {
			width: 30%;
		}

		& .title {
			color: #fff;
			font-size: 16px;
			text-align: left;
			margin-bottom: 30px;
		}

		& .text-izq {
			color: #fff;
			font-size: 18px;
			margin-bottom: 20px;
		}

		& .letter-purple {
			color: #b0b4ff;
		}

		& .letter-yellow {
			color: #ffd777;
		}

		& .MuiInputLabel-outlined.MuiInputLabel-shrink {
			transform: translate(0px, -16px) scale(1) !important;
			font-family: 'Lato' !important;
			font-weight: bold;
			color: #fff !important;
			font-size: 20px;
		}

		& .MuiOutlinedInput-input {
			background: transparent linear-gradient(90deg, #fff 0%, #fff 24%, #fff 68%, #fff 100%) 0% 0% no-repeat padding-box !important;
			color: #54565B !important;
			border: none !important;
		}

		& .MuiInputLabel-outlined {
			color: #54565B !important;
			font-size: 16px;
			font-family: 'Lato' !important;
			font-weight: bold;
		}

		& .MuiInputBase-root {
			margin-bottom: 10px;
		}

		& .MuiOutlinedInput-notchedOutline {
			border: #54565B !important;
		}

		& .MuiFormLabel-root.Mui-focused {
			color: #fff !important;
			font-weight: normal;
			font-size: 14px;
			font-weight: bold;
		}

		& .MuiButton-root:hover {
			text-decoration: none !important;
			background-color: transparent !important;
		}

		& .texto-btn-login {
			font-size: 23px;
			font-weight: bolder;
			z-index: 9;
			color: #fff;
			border: none;
			background: #fa967b;
			font-family: 'Poppins Bold' !important;
			width: 100%;
			cursor: pointer;
			padding: 10px;
		}
	}
}

@media screen and (max-width: 425px) and (orientation: portrait) {}