.bg-header-compass {
  background-color: #000;
  border-bottom: 10px solid #8395ae;
}

.MuiDialog-paperWidthSm {
  max-width: initial !important;
}

.contenedor-popup-celular {
  background-image: url(../../../Resources/images/proMasterRapid2022/actividad/bg.png);
  background-position: center;
  background-size: cover;
  // height: 90vh;
  background-repeat: no-repeat;
  color: #fff;
}

#rapid-promaster-rapid {
  height: 100vh;
  display: table;
  width: 100vw;

  &::after {
    background-image: url(../../../Resources/images/proMasterRapid2022/bg.png);
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: cover;
    background-position: center;
  }

  & .center-vertical {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    flex-wrap: wrap;
  }

  & .contenedor-form-wagoneer {
    display: table-cell;
    vertical-align: middle;

    & .form-ingreso {
      text-align: center;

      & .tooltip {
        font-size: 22px;
        text-align: left;
      }

      & .privacidad {
        position: absolute;
        bottom: 30px;
      }

      & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -17px) scale(1) !important;
        font-family: "Lato" !important;
        font-weight: normal;
        font-size: 20px;
      }

      & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(0px, -25px) scale(1) !important;
        font-family: "Lato" !important;
        font-size: 20px;
        color: #ccc;
        font-weight: bold;
      }

      & .MuiOutlinedInput-input {
        background: transparent !important;
        border: 1px solid #ccc !important;
        border-radius: 0 !important;
        color: #ccc;
      }

      & .MuiInputLabel-outlined {
        color: #ccc;
        font-weight: normal;
        font-size: 20px;
        font-family: "Lato" !important;
      }

      & .MuiOutlinedInput-notchedOutline {
        border: #3d5295 !important;
      }

      & .MuiFormLabel-root.Mui-focused {
        font-weight: normal;
        font-size: 20px;
      }

      & .MuiButton-root:hover {
        text-decoration: none !important;
        background-color: transparent !important;
      }

      & .texto-btn-login {
        padding: 5px;
        background-image: url(../../../Resources/images/proMasterRapid2022/btnEntrar.png);
        width: 200px;
        height: 60px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}

.instrucciones-pro-master-rapid {
  // background-image: url(../../../Resources/images/proMasterRapid2022/popup.png);
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  // padding: 25px;
  border-radius: 10px;
  font-size: 18px;
  color: #565656;

  & .btn-intrucciones {
    background: red;
    color: #fff;

    &:hover {
      background: red;
      color: #fff;
    }
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  #rapid-promaster-rapid {
    padding-top: 60px;
    padding-bottom: 60px;
    // & .center-vertical {
    //   display: initial;
    //   & img {
    //     margin-top: 30px;
    //   }
    // }

    // & .contenedor-form-compass {
    //   & .form-ingreso {
    //     margin-top: 20px;

    //     & .privacidad {
    //       position: initial;
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: 1100px) and (orientation: landscape) {
  // #compass {
  //   margin-bottom: 30px;
  //   & .center-vertical {
  //     display: flex;
  //     & img {
  //       margin-top: 30px;
  //     }
  //   }

  //   & .contenedor-form-compass {
  //     & .form-ingreso {
  //       margin-top: 20px;
  //     }
  //   }
  #rapid-promaster-rapid {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
