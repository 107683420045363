#myHeader {
    padding: 5px !important;

    & .img-logos {
        width: 250px;
        padding: 5px 10px;
    }


}

.blanco {
    background-color: #fff;
}

.sticky {
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 99;
    transition: .5s ease-in;
}



@media screen and (max-width: 500px) and (orientation: portrait) {
    #myHeader {
        text-align: center;
        & .img-logos {
            width: 150px;
        }
    }
}
