#footer {
    color: #fff;

    & .icon-footer {
        width: 40px;
        margin-top: 80px;
        margin-bottom: 20px;
        margin-right: 10px;
        cursor: pointer;
    }

    & img.link-posicion {
        position: relative;
        top: -9px;
    }

    & .flex {
        display: flex;

        & div {
            margin-top: auto;
        }
    }
}


@media screen and (max-width: 1100px) and (orientation: portrait) {}

@media screen and (max-width: 1100px) and (orientation: landscape) {}