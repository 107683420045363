.bg-header-cya-streaming {
	background-color: #d7ca94;
}

#cya-streaming {
	& .seccion-dos {
		background-image: url('../../../Resources/images/cya/Fondo2.png');
		background-position: center top;
		background-size: cover;
		padding: 25px 0 0 0;
		margin: -10px;
		overflow: hidden;
	}
}

.titulos-footer {
	 color: #3d5295 !important;
	 font-size: 18px;
	 font-weight: bold;
}

// .principal.cya {
// 	padding: 0 !important;
// }

// .principal.cya .seccion-uno .titulo {
// 	width: 85%;
// }

// .principal.cya
/* .principal.cya .seccion-unoo {
    background-image: url('../../../Resources/imagenes/cya/Fondo1.png');
    background-position: center top;
    background-size: cover;
    padding: 32px 0 32px 0;
    margin: -10px;
    overflow: hidden;
} */

// .principal.cya a {
// 	color: #3d5295 !important;
// 	font-size: 18px;
// 	font-weight: bold;
// }

// .react-chat-message {
// 	margin: 2px 0 20px !important;
// }

// .react-chat-additional:last-child {
// 	display: none;
// }

@media screen and (max-width: 768px) and (orientation: portrait) {
	// .react-chat-viewerBox {
	// 	height: 0 !important;
	// }

	// .principal.cya .seccion-dos {
	// 	margin: -5px 0 0 0;
	// }
}

@media screen and (min-width: 426px) and (max-width: 1024px) and (orientation: portrait) {
	// .react-chat-viewerBox {
	// 	height: 0 !important;
	// }

	// .principal.cya .seccion-dos {
	// 	margin: -10px;
	// }

	// .principal.cya .seccion-dos {
	// 	margin: -5px 0 70px 0;
	// }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
	// .principal .contenedor-streaming {
	// 	height: auto !important;
	// }

	// .react-chat-viewerBox {
	// 	height: 50vh !important;
	// }

	// .principal.cya .seccion-dos {
	// 	margin: -5px 0 0 0;
	// }
}