#escuelaDeClientesPalmex-streaming {
	padding: 0 0 5% 0;
	background-image: url(../../../Resources/images/escuelaDeClientesPalmex/landing.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;

	& header {
		padding: 15px;
	}

	& #seccion-uno {
		margin-bottom: 5%;
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	#escuelaDeClientesPalmex-streaming {
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	#escuelaDeClientesPalmex-streaming {
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
