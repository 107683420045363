#bridgestone-streaming {
    & .seccion-dos {
        background-image: url('../../../Resources/images/bridgestone/Fondo2.png');
        background-position: center top;
        background-size: cover;
        padding: 0px 0 0 0;
        margin: -10px;
        overflow: hidden;
    }
}






// .principal.bridgestone {
//     padding: 0 !important;
// }


// .principal.bridgestone .seccion-uno .titulo {
//     width: 85%;
// }

// .principal.bridgestone .seccion-dos {
//     background-image: url('../../../Resources/images/bridgestone/Fondo2.png');
//     background-position: center top;
//     background-size: cover;
//     padding: 0px 0 0 0;
//     margin: -10px;
//     overflow: hidden;
// }

// .react-chat-message {
//     margin: 2px 0 20px !important;
// }

// .react-chat-additional:last-child {
//     display: none;
// }

@media screen and (max-width: 768px) and (orientation: portrait) {
    // .react-chat-viewerBox {
    //     height: 0 !important;
    // }

    // .principal.bridgestone .seccion-dos {
    //     margin: -5px 0 0 0;
    // }
}

@media screen and (min-width: 426px) and (max-width: 1024px) and (orientation: portrait) {
    // .react-chat-viewerBox {
    //     height: 0 !important;
    // }

    // .principal.bridgestone .seccion-dos {
    //     margin: -10px;
    // }
    // .principal.bridgestone .seccion-dos {
    //     margin: -5px 0 70px 0;
    // }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    // .principal .contenedor-streaming {
    //     height: auto !important;
    // }

    // .react-chat-viewerBox {
    //     height: 50vh !important;
    // }

    // .principal.bridgestone .seccion-dos {
    //     margin: -5px 0 0 0;
    // }
}