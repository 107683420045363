.bg-header-cya {
	background-color: #3c539a;
}

#costumer-centricity {
	padding-top: 240px;
	overflow: hidden;
	background-image: url(../../../Resources/images/consumerCentricity/Bglogin.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	height: 100vh;
	display: table;
	width: 100vw;

	& .form-ingreso {
		text-align: center;
		border: 5px solid #b8f8be;
		position: relative;
		left: 80px;
		padding: 40px !important;

		& .btn-login {
			background-color: #04571c !important;
			font-size: 23px;
			font-weight: bolder;
			z-index: 9;
			color: #fff;
			font-family: 'Lato' !important;
			padding: 5px;
		}

		& .texto-btn-login {
			font-size: 23px;
			font-weight: bolder;
			z-index: 9;
			color: #fff;
			border: none;
			font-family: 'Lato' !important;
			padding: 5px;
			background: #8395ae;
			width: 200px;
		}

		& .MuiInputLabel-outlined.MuiInputLabel-shrink {
			transform: translate(16px, -21px) scale(1) !important;
			font-family: 'Lato' !important;
			color: #000 !important;
			font-size: 14px;
		}

		& .MuiOutlinedInput-input {
			background: transparent linear-gradient(90deg, #fff 0%, #fff 24%, #fff 68%, #fff 100%) 0% 0% no-repeat padding-box !important;
			color: #000 !important;
			border: none !important;
			text-align: left;
		}

		& .MuiInputLabel-outlined {
			color: #000 !important;
			font-size: 16px;
			font-family: 'Lato' !important;
		}

		& .MuiInputBase-root {
			margin-bottom: 30px;
		}

		& .MuiOutlinedInput-notchedOutline {
			border: #000 !important;
		}

		& .MuiFormLabel-root.Mui-focused {
			color: #000 !important;
			font-weight: normal;
			font-size: 14px;
		}

		& .MuiButton-root:hover {
			text-decoration: none !important;
			background-color: transparent !important;
		}
	}
}

@media screen and (max-width: 2045px) {
	#costumer-centricity {
		& .form-ingreso {
			top: -110px;
			left: 175px;
		}
	}

}
@media screen and (max-width: 425px) and (orientation: portrait) {
	#costumer-centricity {
		background-image: url(../../../Resources/images/consumerCentricity/Bg-movil.png);
		background-position: center left;
		padding: 0;
		height: 120vh;
		margin-top: 0;

		& .form-ingreso {
			position: relative;
			left: 0px;
			top: 145px;
			padding: 10px !important;
		}
	}

}

@media screen and (min-width: 426px) and (max-width: 1024px) and (orientation: portrait) {
	#costumer-centricity {
		background-image: url(../../../Resources/images/consumerCentricity/Bg-movil.png);
		background-position: center top;
		padding: 0;
		height: 100vh;

		& .form-ingreso {
			position: relative;
			left: 0px;
			top: 320px;
			margin-top: 0px;

			& .MuiFormControl-root {
				width: 100% !important;
			}
		}

	}
}

@media screen and (max-width: 1100px) and (orientation: landscape) {
	#costumer-centricity {
		background-image: url(../../../Resources/images/consumerCentricity/Bg-movil.png);
		height: 140vh;
		margin-top: 0;

		& .form-ingreso {
			margin-top: 0px;
			left: 0;

			& .MuiFormControl-root {
				width: 80% !important;
			}
		}
	}
}