.header-heineken {
  background: linear-gradient(to right, #4d8844, #273e4a);
}

#heineken {
  &::after {
    background-image: url(../../../Resources/images/heineken2/Bg01.png);
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: cover;
    background-position: center;
  }
  & .form-ingreso {
    width: 100%;

    & .bg-heineken {
      background: url(../../../Resources/images/heineken2/Loging.png);
      padding: 100px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(4px, -26px) scale(1) !important;
      font-family: "Lato" !important;
      font-weight: bold;
      color: #fff !important;
      font-size: 22px;
    }

    & .MuiOutlinedInput-input {
      background: transparent linear-gradient(90deg, #fff 0%, #fff 24%, #fff 68%, #fff 100%) 0% 0% no-repeat padding-box !important;
      color: rgb(72, 72, 72) !important;
      border: none !important;
      font-size: 22px;
      padding: 12px;
      font-family: "Lato" !important;
    }

    & .MuiInputLabel-outlined {
      color: rgb(72, 72, 72) !important;
      font-size: 22px;
      font-family: "Lato" !important;
    }

    & .MuiInputBase-root {
      // margin-bottom: 30px;
    }

    & .MuiOutlinedInput-notchedOutline {
      color: #fff !important;
      font-size: 22px;
      font-weight: bold;
    }

    & .MuiFormLabel-root.Mui-focused {
      color: #fff !important;
      font-weight: bold;
      font-size: 22px;
    }

    & .MuiButton-root:hover {
      text-decoration: none !important;
      background-color: transparent !important;
    }
    & .btn-login {
      border: 3px solid #3d5295 !important;
      font-size: 22px;
      font-weight: bolder;
      z-index: 9;
      color: #3d5295;
      font-family: "Lato" !important;
      padding: 5px;
    }
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  #heineken {
    & .form-ingreso {
      & .bg-heineken {
        background-image: none;
        padding: 0;
      }
    }
    //     & .form-cya {
    //       & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    //         font-size: 15px;
    //       }
    //     }
  }
}

@media screen and (max-width: 1100px) and (orientation: landscape) {
  #heineken {
    & .form-ingreso {
      & .bg-heineken {
        background-color: rgba($color: #4d8844, $alpha: 0.5);
        background-image: none;
        border-radius: 25px;
      }
    }
  }
}
