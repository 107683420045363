#promasterrapid-streaming {
    & .seccion-unoo {
        // background-image: url('../../../Resources/images/wagoneer/bg.png');
        // background-position: center top;
        // background-size: cover;
        // padding: 32px 0 32px 0;
        overflow: hidden;
    }

    & .seccion-dos {
        background-image: url('../../../Resources/images/proMasterRapid2022/Fondo2.png');
        background-position: center bottom;
        background-size: cover;
        padding: 25px 0 130px 0;
        margin-bottom: -10px;
        margin-top: -10px;
        overflow: hidden;
        height: 580px;

        & .logo-spira {
            width: 150px;
            margin-top: 30px;
        }
    }

}