#nestle {
    & .seccion-uno {
        background-image: url('../../Resources/images/nestle/Fondo1.png');
        background-position: center top;
        background-size: cover;
        padding: 12px 0 20px 0;

        & .titulo {
            width: 85%;
        }
    }

    & .seccion-dos {
        background-image: url('../../Resources/images/nestle/Fondo2.png');
        background-position: center top;
        background-size: cover;
        padding: 30px 0 0 0;
    }
}