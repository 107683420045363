#promasterrapid-actividad {
  & .seccion-unoo {
    background-image: url('../../../Resources/images/proMasterRapid2022/actividad/fondo1.png');
    background-position: center top;
    background-size: cover;
    padding: 32px 0 32px 0;
    overflow: hidden;
    height: 90vh;
  }

  & .seccion-dos {
    background-image: url("../../../Resources/images/proMasterRapid2022/actividad/bg01.png");
    background-position: center bottom;
    background-size: cover;
    padding: 40px 0 130px 0;
    margin-bottom: -10px;
    margin-top: -10px;
    overflow: hidden;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      opacity: 1; /* Firefox */
    }

    & input {
      //   width: 100%;
      resize: none;
      // transform: translateY(-50%);
      border: 0;
      padding: 10px;
      font-size: 22px;
      background: transparent;
      border: dotted;
      border-color: #fff;
      color: #fff;
    }
    & .contenedor-actividad {
      background-image: url(../../../Resources/images/proMasterRapid2022/actividad/bg.png);
      background-position: center bottom;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 120px 0 105px 0;
      margin-bottom: 50px;

      & .asa {
        background-image: url(../../../Resources/images/proMasterRapid2022/actividad/textura.png);
        // background-color: red;
        padding: 46px 34px 5px 34px;
        width: fit-content;
        margin: auto;
        position: relative;
        & label {
          display: inline-block;
          width: 60%;
          font-weight: bold;
          font-size: 30px;
          position: absolute;
          top: 56%;
          left: 11%;
          text-align: left;
        }
        & .nombre {
          display: inline-block;
          width: 44%;
          font-weight: bold;
          font-size: 28px;
          position: absolute;
          top: 1%;
          left: 29%;
          color: #fff;
        }
      }
    }
    & .contenedor-colores {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;

      & .btn-color {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-right: 20px;
        cursor: pointer;
      }

      & .border {
        // border: 4px solid #fff;
        box-shadow: 0px 0px 0px 6px #fff inset;
      }

      & .btn-color.colorUno {
        background-color: #c4c306;
      }
      & .btn-color.colorDos {
        background-color: #9fd72b;
      }
      & .btn-color.colorTres {
        background-color: #08967b;
      }
      & .btn-color.colorCuatro {
        background-color: #833f91;
      }
      & .btn-color.colorCinco {
        background-color: #e432a1;
      }
      & .btn-color.colorSeis {
        background-color: #e92d5c;
      }
      & .btn-color.colorSiete {
        background-color: #cf0843;
      }
      & .btn-color.colorOcho {
        background-color: #dd4706;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  #promasterrapid-actividad {
    & .seccion-dos {
      & .contenedor-actividad {
        background: none;
        padding: 0;
        & img {
          width: 550px;
        }
      }
    }
  }
}
