#bettertogether-streaming {
	padding: 0 !important;
	overflow: hidden;

	& .seccion-unoo {
		padding-left: 57px;
		background-color: #656bc0;
	}

	& .seccion-dos {
		background-image: url('../../../Resources/images/bettertogether/Fondo2.png');
		background-position: center top;
		background-size: cover;
		padding: 0px 0 0 0;
		margin: -10px;
		overflow: hidden;
		color: #fff;

		& .icon-footer {
			width: 40px;
			margin-top: 80px;
			margin-bottom: 20px;
			margin-right: 10px;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 425px) and (orientation: portrait) {}
@media screen and (max-width: 1440px) and (orientation: landscape) {}