.bg-header-cya {
	background-color: #3c539a;
}

#cya {
	&::after {
		background-image: url(../../../Resources/images/cya/bg-login.png);
		content: "";
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: fixed;
		z-index: -1;
		background-repeat: no-repeat;
		background-position-y: 100%;
		background-position-x: center;
		background-size: cover;
		background-position: center;
	}

	& .form-cya {
		border: 5px solid #fff;
		padding: 45px !important;
		margin-top: 45px !important;

		& .MuiInputLabel-outlined.MuiInputLabel-shrink {
			transform: translate(14px, -17px) scale(1) !important;
			font-family: 'Lato' !important;
			font-weight: normal;
			color: #3d5295 !important;
			font-size: 16px;
		}

		& .MuiInputLabel-outlined.MuiInputLabel-shrink {
			transform: translate(16px, -34px) scale(1) !important;
			font-family: 'Lato' !important;
			font-weight: bold;
			color: #3d5295 !important;
			font-size: 20px;
			border-bottom: 3px solid;
		}

		& .MuiOutlinedInput-input {
			background: transparent linear-gradient(90deg, #fff 0%, #fff 24%, #fff 68%, #fff 100%) 0% 0% no-repeat padding-box !important;
			border-radius: 23px !important;
			color: #3d5295 !important;
			border: none !important;
			// margin-bottom: 30px;
		}

		& .MuiInputLabel-outlined {
			color: #3d5295 !important;
			font-size: 16px;
			font-family: 'Lato' !important;
		}

		& .MuiInputBase-root {
			margin-bottom: 30px;
		}

		& .MuiOutlinedInput-notchedOutline {
			border: #3d5295 !important;
		}

		& .MuiFormLabel-root.Mui-focused {
			color: #3d5295 !important;
			font-weight: normal;
			font-size: 14px;
		}

		& .MuiButton-root:hover {
			text-decoration: none !important;
			background-color: transparent !important;
		}
		& .btn-login {
			border: 3px solid #3d5295 !important;
			font-size: 23px;
			font-weight: bolder;
			z-index: 9;
			color: #3d5295;
			font-family: 'Lato' !important;
			padding: 5px;
		}
	}
}

@media screen and (max-width: 425px) and (orientation: portrait) {
	#cya {
		& .form-cya {
			& .MuiInputLabel-outlined.MuiInputLabel-shrink {
				font-size: 15px;
			}
		}
	}
}

@media screen and (max-width: 1100px) and (orientation: landscape) {}