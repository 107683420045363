.bg-header-compass {
  background-color: #000;
  border-bottom: 10px solid #8395ae;
}

#compass {
  height: 100vh;
  display: table;
  width: 100vw;

  &::after {
    background-image: url(../../../Resources/images/compass/bg_login.png);
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: cover;
    background-position: center;
  }

  & .contenedor-form-compass {
    display: table-cell;
    vertical-align: middle;

    & .privacidad {
      color: #46484d;
    }

    & .form-ingreso {
      text-align: center;
      background-image: url(../../../Resources/images/compass/login_img01.png);
      padding: 80px 40px 40px 40px;

      & .tooltip {
        font-size: 22px;
        text-align: left;
        color: #46484d !important; 
      }

      & .privacidad {
        position: absolute;
        bottom: 30px;
      }

      &.bg-compass {
        // background-color: #262626;
        // border: 3px solid #c2854a;
        // padding: 40px;
      }

      & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -17px) scale(1) !important;
        font-family: "Lato" !important;
        font-weight: normal;
        font-size: 20px;
      }

      & .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(0px, -25px) scale(1) !important;
        font-family: "Lato" !important;
        font-size: 20px;
        color: #46484d;
        font-weight: bold;
      }

      & .MuiOutlinedInput-input {
        background: #f9eada !important;
        border: 1px solid #ccc !important;
        border-radius: 0 !important;
        color: #46484d;
      }

      & .MuiInputLabel-outlined {
        color: #46484d;
        font-weight: normal;
        font-size: 20px;
        font-family: "Lato" !important;
      }

      & .MuiOutlinedInput-notchedOutline {
        border: #3d5295 !important;
      }

      & .MuiFormLabel-root.Mui-focused {
        font-weight: normal;
        font-size: 20px;
      }

      & .MuiButton-root:hover {
        text-decoration: none !important;
        background-color: transparent !important;
      }

      & .texto-btn-login {
        padding: 5px;
        background-image: url(../../../Resources/images/compass/btn_entrar.png);
        width: 200px;
        height: 60px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}

.instrucciones-compass {
  color: #565656;
  background: #ebe1d7;

  & .btn-intrucciones {
    background: red;
    color: #fff;

    &:hover {
      background: red;
      color: #fff;
    }
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  #compass {
    margin-bottom: 30px;

    & .contenedor-form-compass {
      & .form-ingreso {
        margin-top: 20px;

        & .privacidad {
          position: initial;
        }
      }
    }
  }
  .instrucciones-compass {
    & img {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 1100px) and (orientation: landscape) {
  #compass {
    margin-bottom: 30px;

    & .contenedor-form-compass {
      & .form-ingreso {
        margin-top: 20px;
      }
    }
  }
}
