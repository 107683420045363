.voltear-pantalla {
    max-width: 800px;
    padding: 15px;
    text-align: center;
    font-size: 16px;
    color: black;
    font-weight: bold;
    background-image: url(../../Resources/images/proMasterRapid2022/actividad/bg01.png);
}

.voltear-pantalla .imagenes img {
    width: 200px;
}

.voltear-pantalla .imagenes {
    display: grid;
    justify-content: center;
}

.voltear-pantalla {
    /* border-radius: 26px; */
}

.voltear-pantalla .contenedor-texto-voltear-pantalla {
    /* border-radius: 36px; */
    padding: 20px;
    margin-top: 30px;
    color: #002E6D;
    height: 100vh;
    display: grid;
    font-size: 26px;
    font-weight: bold;
}

/* .voltear-pantalla .morado {
    background: #802F9367;
}

.voltear-pantalla .verde {
    background: #09757967;
} */

@media only screen and (orientation: portrait) {
    .voltear-pantalla {
        display: block;
    }

    .pantalla-oculta {
        display: none;
    }
}

@media only screen and (orientation: landscape) {
    .voltear-pantalla {
        display: none !important;
    }

    .pantalla-oculta {
        display: block;
    }
}