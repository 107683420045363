#compass-streaming {
    & .seccion-unoo {
       background: #000;
        // background-image: url('../../../Resources/images/wagoneer/bg.png');
        background-position: center top;
        background-size: cover;
        // padding: 32px 0 32px 0;
        overflow: hidden;
    }

    & .seccion-dos {
        background-image: url('../../../Resources/images/compass/Fondo2.jpg');
        background-position: center;
        background-size: cover;
        padding: 25px 0 30px 0;
        margin-bottom: -10px;
        overflow: hidden;
        height: 650px;

        & .logo-spira {
            width: 150px;
            margin-top: 30px;
        }
    }

}