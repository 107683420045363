#escuelaDeClientesPalmex {
	overflow: hidden;
	& header {
		background-image: url(../../../Resources/images/escuelaDeClientesPalmex/header.png);
		background-repeat: no-repeat;
	}
	& .container-login {
		border-top: 10px solid #fff;
		background-image: url(../../../Resources/images/escuelaDeClientesPalmex/login.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		height: 100vh;

		& .form {
			top: 23%;
			left: 48%;
			position: relative;

			& form {
				width: 30%;

				& .logo-mobile {
					display: none;
					margin-bottom: 6%;
				}

				& .MuiInputBase-input {
					color: #252525 !important;
					background: #fff;
					font-size: 22px;
					margin-bottom: 10%;
				}

				& .MuiInputLabel-outlined.MuiInputLabel-shrink {
					transform: translate(0px, -22px) scale(1) !important;
					color: #fff !important;
				}

				& .MuiFilledInput-underline {
					&::before,
					&::after {
						border: none !important;
					}
				}

				& .MuiFormLabel-root.Mui-focused {
					color: #fff !important;
					font-weight: normal;
					font-size: 14px;
				}

				& .MuiOutlinedInput-notchedOutline {
					border: none !important;
				}

				& .btn-login-background {
					& img {
						margin-top: 25%;
						width: 80%;
					}
				}
			}
		}
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	#escuelaDeClientesPalmex {
		& header {
			padding: 15px;
			background-color: #bd2f2b;
			background-image: none;
			& img {
				width: 50%;
			}
		}
		& .container-login {
			border-top: none;
			background-image: url(../../../Resources/images/escuelaDeClientesPalmex/Fondo_movil.jpg);

			& .form {
				top: 13%;
				left: 15%;
				& form {
					width: 70%;

					& .logo-mobile {
						display: block;
					}

					& .btn-login-background {
						& img {
							margin-top: -2%;
						}
					}
				}
			}
		}
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	#escuelaDeClientesPalmex {
		& header {
			background-color: #bd2f2b;
			background-image: none;
		}

		& .container-login {
			border-top: none;
			background-image: url(../../../Resources/images/escuelaDeClientesPalmex/Fondo_movil.jpg);
			height: auto;
			padding-bottom: 10%;

			& .form {
				top: 3%;
				left: 15%;
				& form {
					width: 70%;

					& .logo-mobile {
						display: block;
					}

					& .MuiInputBase-input {
						margin-bottom: 3%;
					}

					& .btn-login-background {
						& img {
							margin-top: -2%;
						}
					}
				}
			}
		}
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	#escuelaDeClientesPalmex {
		& header {
			background-color: #bd2f2b;
			background-image: none;
		}

		& .container-login {
			border-top: none;
			background-image: url(../../../Resources/images/escuelaDeClientesPalmex/Fondo_movil.jpg);
			height: 100vh;
			padding-bottom: 0%;

			& .form {
				& form {
					& .btn-login-background {
						& img {
							margin-top: 5%;
						}
					}
				}
			}
		}
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	#escuelaDeClientesPalmex {
		& header {
			background-image: url(../../../Resources/images/escuelaDeClientesPalmex/header.png);
		}
		& .container-login {
			border-top: 10px solid #fff;
			background-image: url(../../../Resources/images/escuelaDeClientesPalmex/login.png);
			& .form {
				top: 23%;
				left: 48%;

				& form {
					width: 30%;

					& .logo-mobile {
						display: none;
					}

					& .btn-login-background {
						& img {
							margin-top: 25%;
							width: 80%;
						}
					}
				}
			}
		}
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
