#academiaServiazgo-streaming {
    display: grid;
    overflow: hidden;

    & .seccion-dos {
        background-image: url('../../../Resources/images/academiaServiazgo/Fondo1.png');
        background-position: center top;
        background-size: cover;
        padding: 0px 0 40px 0;
        margin: -10px;
        overflow: hidden;
    }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
}

@media screen and (min-width: 426px) and (max-width: 1024px) and (orientation: portrait) {
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
}