@font-face {
  font-family: "Neo Sans Std";
  src: url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.eot");
  src: url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.woff") format("woff"), url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.svg#Neo Sans Std") format("svg");
}

#academiaServiazgo-streaming {
  display: grid;
  // overflow: hidden;

  & .seccion-dos {
    background-image: url("../../../Resources/images/primax/landing_bg.jpg");
    background-position: center top;
    background-size: cover;
    padding: 0px 0 40px 0;
    margin: -10px;
    overflow: hidden;
  }
}
.header {
  width: 270px;
  margin: 20px;
}
.contenedor-streaming {
  // margin-top: 900px;
}
.contenedor-chat {
  // height: 35%;
  // margin-top: 900px;
}

@media screen and (max-width: 768px) and (orientation: portrait) {
    #academiaServiazgo-streaming {
        display: grid;
        overflow: hidden;
    
        & .seccion-dos {
          background-image: url("../../../Resources/images/primax/landing_bg.jpg");
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: initial;
        }
      }
    
      .contenedor-streaming {
        // margin-top: 350px;
      }
      .contenedor-chat {
        // margin-top: 0px;
      }
}

@media screen and (min-width: 426px) and (max-width: 1024px) and (orientation: portrait) {
  #academiaServiazgo-streaming {
    display: grid;
    overflow: hidden;

    & .seccion-dos {
      background-image: url("../../../Resources/images/primax/landing_bg.jpg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: initial;
    }
  }

  .contenedor-streaming {
    // margin-top: 350px;
  }
  .contenedor-chat {
    // margin-top: 0px;
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
}
