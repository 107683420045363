#vhd {
    &::after {
        background-image: url(../../Resources/images/vhd/Fondo-01.png);
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: fixed;
        z-index: -1;
        background-repeat: no-repeat;
        background-position-y: 100%;
        background-position-x: center;
        background-size: cover;
        background-position: center;
    }

    & .size-img {
        width: 30%;
        margin-top: 6%;
        margin-bottom: 6%;
    }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
    #vhd {
        & .size-img {
            width: 80%;
        }
    }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
    #vhd {
        & .size-img {
            width: 80%;
        }
    }
}