#Heineken-streaming {
  // &::after {
  //   background-image: url(../../../Resources/images/heineken2/Bg02.png);
  //   content: "";
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   position: fixed;
  //   z-index: -1;
  //   background-repeat: no-repeat;
  //   background-position-y: 100%;
  //   background-position-x: center;
  //   background-size: cover;
  //   background-position: center;
  // }

  & .seccion-dos {
    background-image: url(../../../Resources/images/heineken2/Fondo2.png);
    background-position: center;
    background-size: cover;
    padding: 25px 0 30px 0;
    margin-bottom: -10px;
    margin-top: -10px;
    overflow: hidden;
    // height: 400px;

    & #video {
      border: 5px solid #4d8844 !important;
    }

    & .contenedor-streaming {
      padding: 56.25% 0 0 0;
      position: relative;

      & .estilo-streaming {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  #Heineken-streaming {
    & .seccion-dos {
      & .contenedor-streaming {
        padding: 0;
        height: 200px;
        position: relative;
  
        & .estilo-streaming {
          position: relative;
          top: 0;
          left: 0;
          width: 96%;
          height: 100%;
          border: none;
        }
      }
    }
  }
}

@media screen and (min-width: 426px) and (max-width: 1024px) and (orientation: portrait) {
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  #Heineken-streaming {
    & .seccion-dos {
      padding: 60px 0 150px 0;
      // overflow: hidden;
    }
  }
}
