body {
    margin: 0;
}

#root::after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: cover;
    background-position: center;
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.bold {
    font-weight: bolder;
}

.full {
    width: 100%;
}

.mt-30 {
    margin-top: 30px;
}

.mt-60 {
    margin-top: 60px;
}

.mb-60 {
    margin-bottom: 60px;
}

a {
    color: #fff;
    text-decoration: none;
}

.contenedor-streaming {
    padding: 56.25% 0 0 0;
    position: relative;

    & .estilo-streaming {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

.contentenedor-chat {
    height: 100%;
}

.padre {
    display: table;

    & .hijos {
        align-items: center;
        vertical-align: middle;
    }
}

::-webkit-scrollbar {
    width: 5px !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-color: #e94e24 !important;
}

.cursor {
    cursor: pointer;
}

@media screen and (max-width: 390px) and (orientation: portrait) {
    .contentenedor-chat {
        height: 400px;
    }

    ::-webkit-scrollbar {
        width: 0 !important;
    }
}

@media screen and (min-width: 391px) and (max-width: 820px) and (orientation: portrait) {
    .contentenedor-chat {
        height: 600px;
    }

    ::-webkit-scrollbar {
        width: 0 !important;
    }
}

@media screen and (max-width: 1180px) and (orientation: landscape) {
    .contentenedor-chat {
        height: 600px;
    }

    ::-webkit-scrollbar {
        width: 0 !important;
    }
}