@font-face {
	font-family: "EncodeSansExpanded Bold";
	src: url("../../../Resources/fonts/EncodeSansExpanded-Bold.ttf");
}

@font-face {
	font-family: "EncodeSansExpanded Regular";
	src: url("../../../Resources/fonts/EncodeSansExpanded-Regular.ttf");
}

#stellantis-streaming {
	// overflow: hidden;
	padding-bottom: 30px;
	overflow-y: scroll !important;
	background-image: url(../../../Resources/images/stellantis/landing_bg.png);
	// height: 100vh;
	background-position: center top;
	background-position-y: 24%;
	background-repeat: no-repeat;
	background-size: cover;

	& .seccion-unoo {
		// background-image: url('../../../Resources/images/stellantis/Fondo1.jpg');
		background-position: center top;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 0px 0 0px 0;
		height: 80vh;
	}

	& .seccion-dos {
		// background-image: url('../../../Resources/images/stellantis/Fondo2.jpg');
		background-position: center top;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 0px 0 60px 0;
		margin-top: -4px;
		color: #fff;
	}

	& .te-esperamos {
		text-align: center;
		font-size: 64px;
		color: #fff;
		margin-top: 20px;
		margin-bottom: 10px;
		font-family: "EncodeSansExpanded Bold";
		font-weight: bolder;
	}

	& .fecha {
		font-size: 45px;
		color: #f5a509;
		font-family: "EncodeSansExpanded Bold";
		font-weight: bolder;
		padding-bottom: 30px;
	}

}

.contenedor-encuesta {
	line-height: 27px;
	margin: auto;
	background: #fff;
	color: #00388b;
	padding: 30px 30px;
	border-radius: 10px;
	font-size: 1rem;
	font-family: "EncodeSansExpanded Regular";

	& .MuiTypography-body1 {
		font-family: "EncodeSansExpanded Regular";
	}

	& .MuiRadio-colorSecondary.Mui-checked {
		color: #1fb2a4 !important;
	}



	& .descargar {
		width: 40%;
	}


}

@media only screen and (max-width: 767px) and (orientation: portrait) {
	.MuiDialog-paper {
		margin: 0 !important;
	}

	#stellantis-streaming {
		background-image: url(../../../Resources/images/stellantis/login_bg_mobile.png);

		& .seccion-unoo {
			height: 67vh;
		}

		& .seccion-unoo {
			background-image: url('../../../Resources/images/stellantis/landing_bg.png');
			height: 300px;
		}

		& .te-esperamos {
			text-align: center;
			font-size: 55px;
			color: #fff;
			margin-top: 20px;
			margin-bottom: 10px;
			font-family: "EncodeSansExpanded Bold";
			font-weight: bolder;
		}

		& .fecha {
			font-size: 45px;
			color: #f5a509;
			font-family: "EncodeSansExpanded Bold";
			font-weight: bolder;
			padding-bottom: 30px;
		}
	}

	.contenedor-encuesta {
		& .descargar {
			width: 100%;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 819px) and (orientation: portrait) {
	#stellantis-streaming {
		background-image: url(../../../Resources/images/stellantis/login_bg.png);

		height: 100vh;


		& .seccion-unoo {
			background-image: url(../../../Resources/images/stellantis/landing_bg.png);
			background-size: cover;
			height: 500px;
		}

		& .seccion-dos {
			background-image: none !important;

			& .te-esperamos {
				font-size: 55px;
			}
		}
	}
}

@media only screen and (min-width: 820px) and (max-width: 880px) and (orientation: portrait) {
	#stellantis-streaming {
		height: 100vh;
		background-image: url(../../../Resources/images/stellantis/login_bg.png);

		& .seccion-unoo {
			background-image: url(../../../Resources/images/stellantis/landing_bg.png);
			height: 500px;
		}

		& .seccion-dos {
			background-image: none;

			& .te-esperamos {
				font-size: 55px;
			}
		}
	}
}

@media screen and (max-width: 1180px) and (orientation: landscape) {
	#stellantis-streaming {

		background-image: url(../../../Resources/images/stellantis/login_bg.png);


		& .seccion-unoo {
			height: 100vh;
			background-image: url('../../../Resources/images/stellantis/landing_bg.png');
		}

		& .seccion-dos {
			margin-top: 5%;
		}

		& .te-esperamos {
			text-align: center;
			font-size: 55px;
			color: #fff;
			margin-top: 20px;
			margin-bottom: 10px;
			font-family: "EncodeSansExpanded Bold";
			font-weight: bolder;
		}

		& .fecha {
			font-size: 45px;
			color: #f5a509;
			font-family: "EncodeSansExpanded Bold";
			font-weight: bolder;
			padding-bottom: 30px;
		}
	}
}