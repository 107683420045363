#container-error {
    margin-bottom: 30px;

    & .btn-regresar {
        width: 20%;
        cursor: pointer;
    }

    & .icon-footer {
        width: 25px;
        margin-right: 10px;
        cursor: pointer;
        margin-top: 40px;
    }
}