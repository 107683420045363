@font-face {
  font-family: "inherit";
  src: url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.eot");
  src: url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.woff") format("woff"), url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.svg#Neo Sans Std") format("svg");
}

@font-face {
  font-family: "EncodeSansExpanded Regular";
  src: url("../../../Resources/fonts/EncodeSansExpanded-Regular.ttf");
}

.headerStellantis {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  & .logo1 {
    width: 300px;
  }

  & .logo2 {
    width: 180px;
    float: right;
    margin-top: 1%;
  }
}


.abner, .titulo-modal {
  font-family: "EncodeSansExpanded Regular";

}
// #root::after {
//   background-image: url(../../../Resources/images/stellantis/login_bg.png);
//   content: "";
//   // top: 12%;
//   // left: 0;
//   // bottom: 0;
//   // right: 0;
//   // position: fixed;
//   // z-index: -1;
//   // background-repeat: no-repeat;
//   // background-size: cover;
//   // background-position: center;
// }

#Stellantis {
  display: grid;
  // height: 100vh;
  margin-top: 5%;

  // &::after {
  //   background-image: url(../../../Resources/images/stellantis/login_bg.png);
  //   content: "";
  //   top: 12%;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   position: fixed;
  //   z-index: -1;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center;
  // }

  & .form-ingreso {
    background-image: url(../../../Resources/images/stellantis/caja_login_desk.png);
    margin-top: 10%;
    background-size: contain;
    background-repeat: no-repeat;

    & .titulo {
      margin-top: 30px;
      margin-bottom: 20px;

      & span {
        color: #474747;
        font-size: 22px;
      }
    }

    & .MuiInput-underline {
      &::after {
        border: none !important;
      }

      &::before {
        border: none !important;
      }
    }

    & .MuiFilledInput-input:-webkit-autofill {
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }

    & .MuiFormLabel-root {
      font-family: "EncodeSansExpanded Regular" !important;
      border: none !important;

      &.Mui-focused {
        color: #ffffff !important;
        border: none !important;
      }
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(0px, -18px) scale(1) !important;
      font-family: "EncodeSansExpanded Regular" !important;
      color: #ffffff !important;
      font-size: 11px;
      border: none !important;
    }

    & .MuiOutlinedInput-input {
      // background: transparent linear-gradient(90deg, $color-azul 0%, $color-azul 24%, $color-azul 68%, $color-azul 100%) 0% 0% no-repeat padding-box !important;
      // background: $color-azul !important;
      border-radius: 10px !important;
      font-family: "EncodeSansExpanded Regular" !important;
      // text-transform: uppercase;
      color: #ffffff !important;
      border: none !important;
    }

    & .MuiInputLabel-standard {
      font-size: 16px;
      font-family: "EncodeSansExpanded Regular" !important;
    }

    & .MuiFormLabel-root {
      color: #ffffff !important;
    }

    & .MuiInputBase-root {
      margin-bottom: 10px;
      border-radius: 20px;
      border: 1px solid #ffffff !important;
    }

    & .MuiFilledInput-underline {
      color: #ffffff !important;
      background-color: transparent;

      &::before,
      &::after {
        border: none !important;
      }
    }
  }
}

.textLogin {
  font-size: 20px;
  font-family: "EncodeSansExpanded Regular";
  color: #d4d4d4;
}

// Small devices (landscape phones, 576px)
@media (max-width: 576px) {
  #Stellantis {
    &::after {
      top: -4%;
    }

    & .form-ingreso {
      background-image: none;
      // background-image: url(../../../Resources/images/stellantis/caja_login_mobile.png);
      // background-size: 340px;
      // background-repeat: no-repeat;
      // margin-top: -1px;
      // background-position-y: 70px;
      // background-position-x: center;
      border-radius: 25px;
      border: 3px solid #66abcd;
      background-color: rgba($color: #042944, $alpha: .7);

      & .titulo {
        margin-top: 30px;

        & img {
          width: 85%;
          // margin-top: 50px;
        }

        & span {
          color: #474747;
          font-size: 22px;
        }
      }

      & .MuiFormLabel-root {
        color: #ffffff !important;
      }

      & .MuiInputBase-root {
        margin-bottom: 10px;
        border-radius: 20px;
        border: 1px solid #fff !important;
      }

      & .MuiFilledInput-underline {
        color: #ffffff !important;
        background-color: transparent;

        &::before,
        &::after {
          border: none !important;
        }
      }
    }
  }

  .textLogin {
    font-size: 21px;
    font-family: inherit;
  }

  .headerStellantis {
    & .logo1 {
      width: 200px !important;
    }

    & .logo2 {
      width: 110px !important;
    }
  }

  .formulario {
    // margin: -20px 50px 140px 40px !important;
  }
}

// Medium devices (tablets, 768px)
@media (max-width: 768px) {}

// Large devices (desktops, 992px)
@media (max-width: 992px) {
  #Stellantis {
    &::after {
      top: -4%;
    }

    & .form-ingreso {
      background-image: none;
      // background-image: url(../../../Resources/images/stellantis/caja_login_mobile.png);
      // background-size: 340px;
      // background-repeat: no-repeat;
      // margin-top: -1px;
      // background-position-y: 70px;
      // background-position-x: center;
      border-radius: 25px;
      border: 3px solid #66abcd;
      background-color: rgba($color: #042944, $alpha: .7);

      & .titulo {
        margin-top: 30px;

        & img {
          width: 85%;
          // margin-top: 50px;
        }

        & span {
          color: #474747;
          font-size: 22px;
        }
      }

      & .MuiFormLabel-root {
        color: #ffffff !important;
      }

      & .MuiInputBase-root {
        margin-bottom: 10px;
        border-radius: 20px;
        border: 1px solid #fff !important;
      }

      & .MuiFilledInput-underline {
        color: #ffffff !important;
        background-color: transparent;

        &::before,
        &::after {
          border: none !important;
        }
      }
    }
  }
}

// X-Large devices (large desktops, 1200px)
@media (max-width: 1200px) {}

// XX-Large devices (larger desktops, 1400px)
@media (max-width: 1400px) {}


// Landscape 
@media screen and (max-width: 1400px) and (orientation: landscape) {
  #Stellantis {
    &::after {
      top: -4%;
    }

    & .form-ingreso {
      background-image: none;
      // background-image: url(../../../Resources/images/stellantis/caja_login_mobile.png);
      // background-size: 340px;
      // background-repeat: no-repeat;
      // margin-top: -1px;
      // background-position-y: 70px;
      // background-position-x: center;
      border-radius: 25px;
      border: 3px solid #66abcd;
      background-color: rgba($color: #042944, $alpha: .7);

      & .titulo {
        margin-top: 30px;

        & img {
          width: 50%;
          // margin-top: 50px;
        }

        & span {
          color: #474747;
          font-size: 22px;
        }
      }

      & .MuiFormLabel-root {
        color: #ffffff !important;
      }

      & .MuiInputBase-root {
        margin-bottom: 10px;
        border-radius: 20px;
        border: 1px solid #fff !important;
      }

      & .MuiFilledInput-underline {
        color: #ffffff !important;
        background-color: transparent;

        &::before,
        &::after {
          border: none !important;
        }
      }
    }
  }

    .headerStellantis {
      & .logo1 {
        width: 200px !important;
      }

      & .logo2 {
        width: 110px !important;
      }
    }
}