#consumer-centricity-streaming {
	& .seccion-dos {
		background-image: url('../../../Resources/images/consumerCentricity/Fondo2.png');
		background-position: center top;
		background-size: cover;
		padding: 25px 0 0 0;
		margin: -10px;
		overflow: hidden;
	}

	& .titulos-footer{
		color: #04571c !important;
		font-size: 16px;
		font-weight: bold;
	}
}


@media screen and (max-width: 768px) and (orientation: portrait) {
	#consumer-centricity-streaming {
		& .seccion-dos {
			margin: -5px 0 0 0;
		}
	}
}

@media screen and (min-width: 426px) and (max-width: 1024px) and (orientation: portrait) {
	#consumer-centricity-streaming {
		& .seccion-dos {
			margin: -5px 0 70px 0;
		}
	}
}

@media screen and (max-width: 1024px) and (orientation: landscape) {}