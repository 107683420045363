@font-face {
  font-family: "DIN Condensed Bold";
  src: url("../../../Resources/fonts/DIN-Condensed-Bold.ttf");
}
@font-face {
  font-family: "Neo Sans Std";
  src: url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.eot");
  src: url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.woff") format("woff"), url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/b0223d78c4400675921eb822fedc122b.svg#Neo Sans Std") format("svg");
}

.bg-header-Primax {
  background-image: url(../../../Resources/images/academiaServiazgo/header-academia-serviazgo.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#primax {
  margin-bottom: 40px;

  &::after {
    background-image: url(../../../Resources/images/primax/login_bg.jpg);
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
    background-repeat: no-repeat;
    background-position-y: 100%;
    background-position-x: center;
    background-size: cover;
    background-position: center;
  }

  & .form-ingreso {
    margin-top: 10%;
    background-color: #fff;
    border-radius: 60px;
    border-style: solid;
    border-color: blue;

    & .titulo {
      color: #1e22aa;
      font-size: 38px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: "Neo Sans Std";

      & span {
        color: #474747;
        font-size: 22px;
      }
    }

    & .MuiInput-underline {
      &::after {
        border: none !important;
      }

      &::before {
        border: none !important;
      }
    }

    & .MuiFormLabel-root {
      font-family: "Montserrat Regular" !important;
      border: none !important;

      &.Mui-focused {
        color: #8b8179 !important;
        border: none !important;
      }
    }

    & .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(0px, -18px) scale(1) !important;
      font-family: "Montserrat Regular" !important;
      color: #8b8179 !important;
      font-size: 11px;
      border: none !important;
    }

    & .MuiOutlinedInput-input {
      // background: transparent linear-gradient(90deg, $color-azul 0%, $color-azul 24%, $color-azul 68%, $color-azul 100%) 0% 0% no-repeat padding-box !important;
      // background: $color-azul !important;
      border-radius: 10px !important;
      font-family: "Montserrat Regular" !important;
      // text-transform: uppercase;
      color: #8b8179 !important;
      border: none !important;
    }

    & .MuiInputLabel-standard {
      font-size: 16px;
      font-family: "Montserrat Regular" !important;
    }

    & .MuiFormLabel-root {
      color: #8b8179 !important;
    }

    & .MuiInputBase-root {
      margin-bottom: 10px;
      border-radius: 20px;
      border: 1px solid #d30a59 !important;
    }

    & .MuiFilledInput-underline {
      color: #8b8179 !important;
      background-color: transparent;

      &::before,
      &::after {
        border: none !important;
      }
    }
  }
}

.header {
  width: 270px;
  margin: 20px;
}

// .img-login {
//     width: 100%;
// }

// .text-login {
//     font-size: 24px;
//     color: #54565B;
//     font-weight: bold;
//     font-family: 'Lato' !important;
//     margin-bottom: 15px;
// }

// .root-ingreso-bridgestone .texto-btn-login {
//     font-size: 23px;
//     font-weight: bolder;
//     z-index: 9;
//     color: #fff;
//     border: none;
//     /* margin-left: 70px; */
//     font-family: 'Lato' !important;
//     /* position: relative; */
//     /* left: -4px; */
//     padding: 5px;
//     background: #e2211b;
//     width: 200px;
// }

// .texto-btn-registro {
//     font-size: 18px;
//     font-weight: bolder;
//     z-index: 9;
//     color: #fff;
//     margin-left: 70px;
//     font-family: 'Lato' !important;
// }

// /* =========== */
// .paper-login {
//     /* margin-top: 64px;
//     display: flex;
//     flex-direction: column;
//     align-items: center; */
//     padding: 40px;
//     /* background: #FFFFFF 0% 0% no-repeat padding-box;
//     box-shadow: 0px 0px 12px #06356029; */
// }

// .form-ingreso {
//     text-align: center;
// }

// .root-ingreso-bridgestone {
//     overflow: hidden;
//     background-image: url(../../../Resources/images/bridgestone/background.jpg);
//     background-repeat: no-repeat;
//     background-position: center top;
//     background-size: cover;
//
// }

// .as {
//     flex-grow: 1;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//     flex-wrap: wrap;
// }

// .root-ingreso-bridgestone .titulo {
//     color: #fff;
//     font-size: 50px;
//     margin-bottom: 20px;
// }

// .content-button-register {
//     display: inline;
// }

// .position-back-login {
//     position: relative;
//     top: 36px;
// }

@media screen and (max-width: 425px) and (orientation: portrait) {
  #primax {
    & .form-ingreso {
      background-image: url(../../../Resources/images/academiaServiazgo/login_cont_mobiñe.png);
      background-position: center;
      background-size: initial;
      background-repeat: no-repeat;
      border-top-right-radius: 10%;
      border-top-right-radius: 154px;
      background-color: #fff;
      border-top-left-radius: 0%;
      border-bottom-right-radius: 0;
    }
  }

  // .root-ingreso-bridgestone {
  //     margin-top: 0;
  // }

  // .root-ingreso-bridgestone {
  //     background-image: url(../../../Resources/images/bridgestone/background.jpg);
  //     background-position: center left;
  // }

  // .root-ingreso-bridgestone .titulo {
  //     font-size: 32px;
  // }

  // .form-ingreso {
  //     margin-top: 0px;
  // }
}

@media screen and (min-width: 426px) and (max-width: 1024px) and (orientation: portrait) {
  #primax {
    & .form-ingreso {
      background-position: center;
      background-size: initial;
      background-repeat: no-repeat;
      border-top-right-radius: 10%;
      border-top-right-radius: 300px;
      background-color: #fff;
      border-top-left-radius: 0%;
      border-bottom-right-radius: 0;
    }
  }

  // .MuiFormControl-root {
  //     width: 100% !important;
  // }

  // .root-ingreso-bridgestone {
  //     background-image: url(../../../Resources/images/bridgestone/background.jpg);
  //     background-position: center left;
  // }

  // .root-ingreso-bridgestone .titulo {
  //     font-size: 32px;
  // }

  // .form-ingreso {
  //     margin-top: 0px;
  // }
}

@media screen and (max-width: 1100px) and (orientation: landscape) {
  #primax {
    & .form-ingreso {
      background-position: center;
      background-size: initial;
      background-repeat: no-repeat;
      border-top-right-radius: 10%;
      border-top-right-radius: 300px;
      background-color: #fff;
      border-top-left-radius: 0%;
      border-bottom-right-radius: 0;
    }
  }

  // .MuiFormControl-root {
  //     width: 80% !important;
  // }

  // .root-ingreso-bridgestone {
  //     background-image: url(../../../Resources/images/bridgestone/background.jpg);
  //     background-position: center left;
  // }

  // .root-ingreso-bridgestone .titulo {
  //     font-size: 32px;
  // }

  // .root-ingreso-bridgestone {
  //     height: 100vh;
  //     margin-top: 0;
  // }

  // .form-ingreso {
  //     margin-top: 0px;
  // }
}

/* Inputs form styles */

// .root-ingreso-bridgestone .MuiFormControl-marginNormal {
//     margin-top: 0 !important;
//     margin-bottom: 0 !important;
// }

// .MuiOutlinedInput-root {
//     border-radius: 23px !important;
//     border: none !important;
// }

// .MuiInputLabel-outlined.MuiInputLabel-shrink {
//     transform: translate(14px, -17px) scale(1) !important;
//     font-family: 'Lato' !important;
//     font-weight: normal;
//     color: #fff !important;
// }

// .root-ingreso-bridgestone .MuiOutlinedInput-input {
//     background: transparent linear-gradient(90deg, #fff 0%, #fff 24%, #fff 68%, #fff 100%) 0% 0% no-repeat padding-box !important;
//     border-radius: 0px !important;
//     color: #54565B !important;
// }

// .MuiInputLabel-outlined {
//     color: #54565B !important;
//     font-size: 21px;
//     font-family: 'Lato' !important;
// }

// .MuiOutlinedInput-notchedOutline {
//     border: #63503c !important;
// }

// .MuiFormLabel-root.Mui-focused {
//     color: #fff !important;
//     font-weight: bold !important;
// }

// .MuiLink-underlineHover:hover {
//     text-decoration: none !important;
// }

// .MuiFormControl-root {
//     width: 20%;
// }

// .MuiButton-root:hover {
//     text-decoration: none !important;
//     background-color: transparent !important;
// }
